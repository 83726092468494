:local {
  .container {
    display: flex;
    flex-direction: column;
    min-height: 3rem;
    padding: 0.5rem;
    text-align: center;

    .title,
    .value {
      font-size: 1rem;
    }

    .title {
      font-weight: bold;
    }

    .value {
      margin: auto 0;
    }
  }
}
