@import "~$CSS/settings";

:local {
  .container {
    align-items: flex-end;
    display: flex;
    flex: 0 1 auto;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &.horizontal {
      flex-flow: row nowrap;
    }    

    .dateInput {
      flex: 1 1;
    }
  }
}