@import "~$CSS/settings";

:local {
  .progressDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}