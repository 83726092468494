@import "~$CSS/settings";

:local {
    .sectionHeader {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: calc(#{$card-padding} / 2);
    }

    .section {
        padding: calc(#{$card-padding} / 2);
        padding-top: $card-padding;
    }

    .sectionRow {
        align-items: center;
        display: flex;
        flex-flow: row;
        gap: 2rem;
    }
}