@import "~$CSS/settings";

:local {
    .flexContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .filterSectionActions {
            font-size: 0.9em;
            color: blue;
            cursor: pointer;
        }

        .actionsDivider {
            margin: 0 0.2rem;
        }
    }

    .filterSectionHeader {
        font-weight: bold;
        padding-top: 10px;
    }
}