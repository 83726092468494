:local {
  .applicationContainer {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: stretch;
  }

  .applicationBar {
  }

  .subContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
  }

  .sideNavigation {
  }

  .mainContent {
    background-color: #e0e0e0;
    color: #0d0d0d;
    flex-grow: 1;
  }

  .applicationBarItems {
    flex-basis: 20%;
    text-align: center;
    justify-content: center;
  }

  .applicationBarTitle {
    flex-basis: 80%;
    text-align: center;
    justify-content: center;
  }

  .applicationBarContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .logo {
    height: 60px
  }

  .appsMenu {
    display: flex;

    button {
      padding: 0px;
    }
  }

  button .appsIcon {
    height: 40px;
    width: 40px;
  }

  .appsMenuItem {
    flex-direction: column;

    svg.appsIcon {
      transform: scale(1.5);
      color: #28343C;
    }
  }

  .applicationBarTab {
    margin-left: 70px;
    flex-grow: 1;

    .applicationBarTabTabs {
      min-height: 63px; 
    }
  }

  .usernameDisplay {
    padding-left: 12px;
    font-size: 1rem;
  }
}
