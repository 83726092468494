/*
 * This will load all fonts and styles of the Source Sans Pro font.
 */

 @font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLight.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLight.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLight.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.svg#SourceSansPro-ExtraLightItalic') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Light.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Light.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Light.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Light.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-LightItalic.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-LightItalic.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-LightItalic.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-LightItalic.svg#SourceSansPro-LightItalic') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Regular.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Regular.woff')  format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Regular.ttf')   format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Italic.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Italic.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Italic.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Italic.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Italic.svg#SourceSansPro-Italic') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBold.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBold.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.svg#SourceSansPro-SemiBoldItalic') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face { 
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Bold.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Bold.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-BoldItalic.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-BoldItalic.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BoldItalic.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BoldItalic.svg#SourceSansPro-BoldItalic') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Black.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-Black.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Black.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Black.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-Black.eot?#iefix') format('embedded-opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-BlackItalic.eot');
  src: url('~$CSS/fonts/SourceSansPro/SourceSansPro-BlackItalic.woff2') format('woff2'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BlackItalic.woff') format('woff'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf') format('truetype'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BlackItalic.svg#SourceSansPro-BlackItalic') format('svg'),
       url('~$CSS/fonts/SourceSansPro/SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype');
  font-weight: 900;
  font-style: italic;
}
