@import "~$CSS/settings";

:local {
    .bordered {
        border-color: lightgray;
        border-style: solid;
        border-width: 2px;
    }

    .cellContent {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}