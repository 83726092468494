@import "~$CSS/settings";

:local {
  .wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .pageCount {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}
