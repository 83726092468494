$app-width: 900px;
$toolbar-height: 55px;

$pale-turquoise: #C6DEF2;
$midnight-blue: #172D57;
$light-blue: #83B6D9;
$steel-blue: #4E7A99;
$light-slate-grey: #7089A9;
$grid-border-grey: #e0e0e0;

$code-font: 'Roboto Mono', monospace;

$card-padding: 10px;