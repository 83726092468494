@import "~$CSS/settings";

:local {
  .mainContainer {
    padding: calc(#{$card-padding} / 2);
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .searchContainer {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    flex-grow: 1;
  }

  .filterContainer {
    flex: 0 0 13rem;

    .filterSectionHeader {
      font-weight: bold;
      padding-top: 10px;
    }
  }

  .reportContainer {
    display: inline-block;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .tooltip {
    border: 1px solid #5a5c63;
    padding: 5px;
    background-color: white;
  }

  .cell .logo {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
  }
}