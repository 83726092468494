:local {
  .controlsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .chips {
      display: flex;
      flex-flow: row wrap;
      gap: 0.25rem;
      justify-content: center;
      margin-top: 0.5rem;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }
}