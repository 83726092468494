@import "~$CSS/settings";

:local {
  .mainContainer {
    padding: $card-padding;
    display: flex;
    flex-flow: column;
    gap: $card-padding;
  }

  .mainCard {
    padding: 10px;
  }

  .reportFilterContainer {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    flex-grow: 1;

    .checkbox {      
      margin-left: 0px;
      margin-right: 0.5rem;

      span {
        margin-bottom: -4px;
      }
    }
  }

  .sectionRow {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    gap: $card-padding;
  }

  .reportFilterSummariesRow {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    gap: 2rem;
  }
}