@import "~$CSS/settings";

:local {
  .mainContainer {
    padding: calc(#{$card-padding} / 2);
    display: flex;
    align-items: flex-start;
    
    .compactCell {
      padding: 0.25rem;

      &:not(:first-of-type) {
        padding-left: 0.625rem;
      }
    }
  }

  .filterContainer {
    display: flex;
    flex-direction: column;
  }

  .bookedSalesContainer {
    vertical-align: top;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .headerRow {
    border-bottom: 1px solid $grid-border-grey;
    font-weight: bold;
  }
  
  .totalRow {
    background-color: white;
    font-weight: bold;
    font-size: 1.1em;
  }

  .tooltip {
    border: 1px solid #5a5c63;
    padding: 0.313rem;
    background-color: white;

    table {
      td.spaceAbove {
        padding-top: 0.625rem;
      }

      td:last-of-type {
        padding-left: 0.625rem;
        font-weight: bold;
      }
    }
  }
}