@import "~$CSS/settings";

:local {
  .mainContainer {
    margin: calc(#{$card-padding} / 2);
    padding: $card-padding;
  }

  .cardHeader {
    font-size: 22px;
    font-weight: bold;
    color: #5a5c63;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 5px;
    border-bottom: 2px solid #8C3F61;
    margin-bottom: 5px;
  }
}