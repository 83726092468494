@import "~$CSS/settings";

:local {
  .mainContainer {
    padding: calc(#{$card-padding} / 2);
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .searchContainer {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    flex-grow: 1;
  }

  .graphCard {
    margin: calc(#{$card-padding} / 2);
    padding: calc(#{$card-padding} / 2);
    flex: 1 0 25.75rem;
  }
}