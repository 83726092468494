@import "./settings.scss";
@import "./fonts/SourceSansPro/font";
@import "./fonts/Roboto/font";

body {
    margin: 0px;
    font-family: 'Roboto', 'Source Sans Pro', sans-serif;
    overflow-y: scroll;
    color: #444;
}

.login-form{
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    margin-top: 30px;
}

// cheap alternative to full-on button restyling or custom component
button.iconAsButton {
    min-width: auto;
    padding: 0.25rem;
}

button.cardHeaderButton {
    top: -0.313rem;
}