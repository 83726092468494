@import "~$CSS/settings";

:local {
    .content {
        align-items: center;
        display: flex;
        flex-flow: column;
        gap: 0.5rem;
        padding-bottom: calc(#{$card-padding} * 2);
        padding-left: calc(#{$card-padding} * 2);
        padding-right: calc(#{$card-padding} * 2);
    }

    .value {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}