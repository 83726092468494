@import "~$CSS/settings";

:local {
  .mainContainer {
    text-align: center;
  }

  .appTitle {
    text-align: center;
    font-size: 48px;
    padding: 24px 0;
    color: #5A5C63;
  }

  .card {
    padding: 24px 0;
    display: inline-block;
    text-align: left;
  }

  .message {
    padding-bottom: 30px !important;
  }
}