@import "~$CSS/settings";

:local {
  .mainContainer {
    padding: calc(#{$card-padding} / 2);
    display: flex;
    flex-direction: row;
    height: 100%;

    .filtersContainer {
      flex: 0 0 13rem;
    }

    .metricsContainer {
      flex: 0 0 auto;
      margin: 1rem 0;
    }

    .reportContainer {
      flex: 1 1 20.5rem;
    }

    .tripDetailsHeader {
      font-weight: bold;
      margin-top: 1rem;
    }
  }
}