:local {
  Div.logo {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
  }

  .customerPortal {
    border: 1px solid black;
    color: black;
  }
}