@import "~$CSS/settings";

:local {
  .mainContainer {
    padding: calc(#{$card-padding} / 2);
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .searchContainer {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    flex-grow: 1;
  }

  .headerRow {
    border-bottom: 1px solid $grid-border-grey;
  }
  
  .totalRow {
    background-color: white;
    font-weight: bold;
  }
}